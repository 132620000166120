namespace $.$${
	$mol_style_define( $hyoo_square_app, {
		
		Tools: {
			flex: {
				grow: 0,
			},
		},
		
		Text_scroll: {
			maxHeight: `50vh`,
		},
		
		Text: {
			align: {
				self: 'flex-start',
			},
		},
		
	} )
}
